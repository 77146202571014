/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// eslint-disable-next-line no-unused-vars
import Home from "./views/Home";
import TopBar from "./components/TopBar";
import ContactList from "./components/ContactList";
import LeftMenu from "./components/LeftMenu";
import ResaList from "./components/ResaList";
import JourneeList from "./components/JourneeList";
import React, { useEffect, useState } from "react";
import Login from "./components/Login";
import MasterList from "./components/MasterList";

const App: React.FC = () => {

  const [login, setLogin] = useState<string>("false");

  
  useEffect(() => {
    if(localStorage.getItem("aqsToken") === "eyrJhbGciOiJIUzssI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMTY5NWFjOThjOWMzOGE5NGNkM2I3OSIsImlhdCI6MTY0NTY1OTY3MiwiZXhwIjoxNjQ2NjU5NjcyfQ.Xmfciv19JUIPzdlxbcYp4fft2EV7STKHnI7vODuPPUc" && localStorage.getItem("aqsLogged") === "true")
    {
      setLogin(localStorage.getItem("aqsLogged") as string);
    }
  });


  return (

    
    <Router>


      {
        (login === "true") ?
        
        <div className="flex flex-row justify between h-full w-full bg-gradient-to-b from-robins-egg-blue-500 to-electric-violet-500">
      <TopBar />
      <div className="Main-content h-full w-full flex overflow-hidden pt-14">
        <div className="flex flex-row justify between h-full w-full bg-gradient-to-b from-robins-egg-blue-500 to-electric-violet-500">
          <LeftMenu />
          <Routes>
            <Route path="/" element={<ResaList />} />
            <Route path="/reservations" element={<ResaList />} />
            <Route path="/journee" element={<JourneeList />} />
            <Route path="/master" element={<MasterList />} />
          </Routes>
          <ContactList />
        </div>
      </div></div> : 

        <div className="scrollView flex flex-row justify-center pt-5 h-full w-full bg-gradient-to-b from-robins-egg-blue-500 to-electric-violet-500">
          <Login />
        </div> 
      }  
      



    </Router>
  );
};

export default App;
