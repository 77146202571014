import React from "react";

export default function useEditTag() {
  // Show editTag for input field
  const addInputEditTag = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    // const parent: HTMLDivElement = event.currentTarget.parentNode
    //   ?.parentNode as HTMLDivElement;
    // const editTag = document.createElement("input");
    // editTag.setAttribute("type", "text");
    // editTag.value = "clic to Edit then hit -Enter-";
    // editTag.id = "editTagId";
    // editTag.className =
    //   "bg-robins-egg-blue-600 font-josefin text-xs rounded-lg";
    // parent.appendChild(editTag);
  };

  // Hide editTag for input field
  const removeInputEditTag = (event: React.MouseEvent<HTMLDivElement>) => {
    const editTagElement = document.getElementById(
      "editTagId"
    ) as HTMLInputElement;
    if (editTagElement) {
      editTagElement.remove();
    }
  };

  // Show editTag for text area field For POST description
  const addTextAreaEditTag = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const parent: HTMLDivElement = event.currentTarget.parentNode
      ?.parentNode as HTMLDivElement;
    const editTag = document.createElement("input");
    editTag.setAttribute("type", "text");
    editTag.value = "clic to Edit then hit -Enter-";
    editTag.id = "editTagId";
    editTag.className = "font-josefin text-xs";
    parent.appendChild(editTag);
  };

  // Hide editTag for text area field For POST description
  const removeTextAreaEditTag = (
    event: React.MouseEvent<HTMLTextAreaElement>
  ) => {
    const editTagElement = document.getElementById(
      "editTagId"
    ) as HTMLInputElement;
    if (editTagElement) {
      editTagElement.remove();
    }
  };

  // Show editTag for text area field For POST description
  const addTextAreaEditTagHashTag = (
    event: React.MouseEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const parent: HTMLDivElement = event.currentTarget.parentNode
      ?.parentNode as HTMLDivElement;
    const editTag = document.createElement("input");
    editTag.setAttribute("type", "text");
    editTag.value = "clic to Edit then hit -Enter-";
    editTag.id = "editTagId";
    editTag.className = "flex font-josefin text-xs ml-3 -mt-6";
    parent.appendChild(editTag);
  };

  // Hide editTag for text area field For POST description
  const removeTextAreaEditTagHashTag = (
    event: React.MouseEvent<HTMLTextAreaElement>
  ) => {
    const editTagElement = document.getElementById(
      "editTagId"
    ) as HTMLInputElement;
    if (editTagElement) {
      editTagElement.remove();
    }
  };

  // Show editTag for text area field For COMMENT description
  const addCommentEditTag = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const parent: HTMLDivElement = event.currentTarget.parentNode
      ?.parentNode as HTMLDivElement;
    const editTag = document.createElement("input");
    editTag.setAttribute("type", "text");
    editTag.value = "clic to Edit then hit -Enter-";
    editTag.id = "editTagId";
    editTag.className =
      "bg-electric-violet-300/50 font-josefin text-xs rounded-lg";
    parent.appendChild(editTag);
  };

  // Hide editTag for text area field For COMMENT description
  const removeCommentEditTag = (
    event: React.MouseEvent<HTMLTextAreaElement>
  ) => {
    const editTagElement = document.getElementById(
      "editTagId"
    ) as HTMLInputElement;
    if (editTagElement) {
      editTagElement.remove();
    }
  };

  return {
    addInputEditTag,
    removeInputEditTag,
    addTextAreaEditTag,
    removeTextAreaEditTag,
    addTextAreaEditTagHashTag,
    removeTextAreaEditTagHashTag,
    addCommentEditTag,
    removeCommentEditTag,
  };
}
