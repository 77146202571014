import React from "react";
import { Link } from "react-router-dom";
import {
  faHandHoldingUsd,
  faStore,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import LeftMenuItem from "./LeftMenuItem";

const LeftMenu: React.FC = () => {

  return (
    <div className="bg-white/30 shrink-0 overflow-auto hidden sm:hidden md:hidden lg:block lg:w-52 xl:block xl:w-52 2xl:w-52 p-0 md:p-5 lg:p-5 xl:p-5 2xl:p-5">
      <div className="font-josefin text-xl mb-2">Menu</div>
      <Link to="/journee">
        <LeftMenuItem icon={faStore} name="Inventaire" />
      </Link>
      <Link to="/reservations">
        <LeftMenuItem icon={faHandHoldingUsd} name="Réservations" />
      </Link>
      <Link to="/master">
        <LeftMenuItem icon={faTools} name="Master" />
      </Link>
    </div>
  );
};

export default LeftMenu;
