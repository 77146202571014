/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from "react";
import stag from "../images/stag.png";
import {
  deleteJourneeDb,
  getFullJournee,
} from "../services/journeeService";
import { journee } from "../types/journee";
import EditableInputFirstName from "./editableLiveForm/EditableInputFirstName";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


type IProps = {
  journee: journee;
  setJournees: (journees: journee[]) => void;
  journees: journee[];
};

const Journee: React.FC<IProps> = ({ journee, setJournees, journees }) => {
  const [jounee, setJounee] = useState<journee>(journee);

  const deleteJournee = async ( ) => {

    await deleteJourneeDb(jounee._id);
    alert(`La journee ID : ${jounee._id} à été supprimé !`);
    journees.forEach((journee, index) => {
      if (journee._id === jounee._id){
        journees.splice(index, 1);
      }
      
    });

    
    setJournees([...journees]);
  };


  return (
    <div className="flex flex-row bg-white rounded-xl m-3 p-3">
      <div className="w-60 pt-10">
        <img className="h-80 w-80" src="http://www.aventurequatresaisons.com/images/canotact.jpg" alt="???" />
      </div>
      <div className="flex flex-col w-auto ">

      <div className="flex justify-end -mb-4">
          <FontAwesomeIcon onClick={deleteJournee} icon={faTrash} />
      </div>
        
        <div className="flex flex-wrap rounded border border-electric-violet-800 m-4">
          <div className="font-yeseva text-2xl mr-4"><p>Date : </p><EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"startDate"} newValue={jounee.startDate}/></div>
          <div className="font-yeseva text-2xl mr-4"><p>Id Journée : </p><EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"_id"} newValue={jounee._id}/></div>
        </div>
        
        <div className="-mb-4"><p>Embarcations Restantes</p></div>
        <div className="flex flex-wrap justify-center rounded border border-electric-violet-800 m-4">
          <div className="font-yeseva text-1xl mr-4"><p>canot(s) : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"canot"} newValue={jounee.canot.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>kayak(s) : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"kayak"} newValue={jounee.kayak.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>sup(s) : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"sup"} newValue={jounee.sup.toString()} ApiCallType={"journee"}/></p></div>
        </div>

        <div className="-mb-4"><p>Places Restantes</p></div>
        <div className="flex flex-wrap justify-center rounded border border-electric-violet-800 m-4">
          
          <div className="font-yeseva text-1xl mr-4"><p>bus 9h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"minibus9"} newValue={jounee.minibus9.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>bus 10h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"minibus10"} newValue={jounee.minibus10.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>bus 11h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"minibus11"} newValue={jounee.minibus11.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>bus 12h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"minibus12"} newValue={jounee.minibus12.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>bus 13h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"minibus13"} newValue={jounee.minibus13.toString()} ApiCallType={"journee"}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>bus 14h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"minibus14"} newValue={jounee.minibus14.toString()} ApiCallType={"journee"}/></p></div>
        </div>

      <div className="-mb-4"><p>Personnes sur le départ :</p></div>
      <div className="flex flex-wrap justify-center rounded border border-electric-violet-800 m-4">
        
        <div className="font-yeseva text-1xl mr-4"><p>bus 9h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"nbPersSurDepRed9"} newValue={jounee.nbPersSurDepRed9.toString()} ApiCallType={"journee"}/></p></div>
        <div className="font-yeseva text-1xl mr-4"><p>bus 10h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"nbPersSurDepRed10"} newValue={jounee.nbPersSurDepRed10.toString()} ApiCallType={"journee"}/></p></div>
        <div className="font-yeseva text-1xl mr-4"><p>bus 11h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"nbPersSurDepRed11"} newValue={jounee.nbPersSurDepRed11.toString()} ApiCallType={"journee"}/></p></div>
        <div className="font-yeseva text-1xl mr-4"><p>bus 12h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"nbPersSurDepRed12"} newValue={jounee.nbPersSurDepRed12.toString()} ApiCallType={"journee"}/></p></div>
        <div className="font-yeseva text-1xl mr-4"><p>bus 13h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"nbPersSurDepRed13"} newValue={jounee.nbPersSurDepRed13.toString()} ApiCallType={"journee"}/></p></div>
        <div className="font-yeseva text-1xl mr-4"><p>bus 14h : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"nbPersSurDepRed14"} newValue={jounee.nbPersSurDepRed14.toString()} ApiCallType={"journee"}/></p></div>
      </div>

<div className="-mb-4"><p>Yourtes :</p></div>
<div className="flex flex-wrap justify-center rounded border border-electric-violet-800 m-4">
  
  <div className="font-yeseva text-1xl mr-4"><p>Voyageuse : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"yourteVoyageuse"} newValue={jounee.yourteVoyageuse.toString()} ApiCallType={"journee"}/></p></div>
  <div className="font-yeseva text-1xl mr-4"><p>Traditionnelle : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"yourteTrad"} newValue={jounee.yourteTrad.toString()} ApiCallType={"journee"}/></p></div>
  <div className="font-yeseva text-1xl mr-4"><p>Perchée : <EditableInputFirstName key={uuidv4()} resaId={jounee._id} fieldToChange={"yourtePerchee"} newValue={jounee.yourtePerchee.toString()} ApiCallType={"journee"}/></p></div>
 </div>




      </div>
    </div>
  );
};

export default Journee;
