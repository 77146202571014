import React from "react";

const ContactList: React.FC = () => {
  return (
    <div className="bg-white/30 shrink-0 overflow-auto w-0 md:w-72 lg:w-72 xl:w-72 2xl:w-72 p-0 md:p-5 lg:p-5 xl:p-5 2xl:p-5">
      <div className="font-josefin text-xl mb-2">CompacList</div>
      <hr></hr>
      {/* <div className="font-josefin mt-5 mb-5 text-sm">
        Bidule de chez machin
      </div>
      <div className="font-josefin mt-5 mb-5 text-sm">
        Bidule de chez prout prout
      </div>
      <div className="font-josefin mt-5 mb-5 text-sm">
        Bidule de chez machin
      </div>
      <div className="font-josefin mt-5 mb-5 text-sm">
        Bidule de chez prout prout
      </div> */}
    </div>
  );
};

export default ContactList;
