import authHeader from "./authService";

// const URL = "https://checkout.aventurequatresaisons.ca:3000/user";
const URL = "https://checkout.aventurequatresaisons.ca:3000/user";

export const login = async (username: string, password: string) => {

  const response = await fetch(`${URL}/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  if (response.status === 401) {
    throw new Error(await response.text());
  } else if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    const data = await response.json();
    if (data.token) {
      localStorage.setItem("aqsToken", data.token);
      localStorage.setItem("aqsPseudo", data.username);
      localStorage.setItem("aqsLogged", data.aqsLogged);
      authHeader();
    }

    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const logout = () => {
  localStorage.removeItem("aqsToken");
  localStorage.removeItem("aqsPseudo");
  localStorage.removeItem("aqsLogged");
  window.location.reload();
};