/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import {
  getFullResa,
} from "../services/reservationService";
import Resa from "./Resa";
import { reservation } from "../types/reservation";
import { v4 as uuidv4 } from "uuid";

const ResaList: React.FC = () => {
const [resas, setResas] = useState<reservation[]>([]);

useEffect(() => {
  getResa();
}, []);

  const getResa = async () => {
    try {
      const fetchedResa: reservation[] = await getFullResa();
      setResas(fetchedResa);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div
      id="wall"
      className="flex flex-col ml-3 mr-1 pr-3 w-full scrollbar-thin scrollbar-thumb-white scrollbar-track-gray overflow-y-scroll"
    >
      <div>
        {resas.map((reservation: reservation) => (
          <Resa key={uuidv4()} reservation={reservation} setResas={setResas} resas={resas}/>
        ))}
      </div>
    </div>
  );

};

export default ResaList;
