import React, { useState, useEffect } from "react";
import useEditTag from "../../hooks/useEditTag";
import { v4 as uuidv4 } from "uuid";
import { patchResaInfo } from "../../services/reservationService";
import { patchJourneeInfo } from "../../services/journeeService";

const { addInputEditTag, removeInputEditTag } = useEditTag();

type IProps = {
  resaId: string;
  newValue: string;
  fieldToChange: string;
  ApiCallType?: string;
  fixedSized?: boolean;
  fixedSized2?: boolean;
  txtAddon?: string;
};

const EditableInputFirstName: React.FC<IProps> = ({ resaId, newValue, fieldToChange, ApiCallType = "", fixedSized = false, fixedSized2 = false, txtAddon = "" }) => {
  const [postText, setText] = useState(newValue); // Devra être une interface avec tous les props d'un post recu de l'API
  const [initialRender, setInitialRenderer] = useState(true);
  const [firstNameInputId] = useState(uuidv4());


  const changeInputWidht = (
    inputField: React.ChangeEvent<HTMLInputElement> | any
  ): void => {

    const inputLenght = inputField.value.length;
    let inputSize = 110;
    if(fixedSized2){
      inputSize = 50;
    }

    if(!fixedSized && !fixedSized2)
    {
      if (inputLenght < 5) {
        inputSize = 55;
        if (inputLenght < 3) {
          inputSize = 20;
        }
      }
      else{
        inputSize = inputField.value.length * 18;
      }
    }

    const resizeCssString = "width:" + inputSize + "px";
    inputField.setAttribute("style", resizeCssString);
  };

  const SendNewTextToApi = async (
    newText: string,
    inputNameElement: HTMLInputElement
  ) => {
    inputNameElement.blur();
    switch (ApiCallType) {
      case "journee":
        await patchJourneeInfo(resaId, { [fieldToChange]: newText });
        
        break;
    
      default:

        await patchResaInfo(resaId, { [fieldToChange]: newText });
        break;
    }

  };
  useEffect(() => {
    if (initialRender === true) {
      setInitialRenderer(false);
    } else {
      SendNewTextToApi(
        postText,
        document.getElementById(firstNameInputId) as HTMLInputElement
      );
    }
  }, [postText]);

  useEffect(() => {
    const PostTextInput = document.getElementById(
      firstNameInputId
    ) as HTMLInputElement;

    setText(newValue);

    changeInputWidht(PostTextInput);
  }, [newValue]);

  const changePostTextOnEnter = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ): void => {
    const PostTextInput = document.getElementById(
      firstNameInputId
    ) as HTMLInputElement;

    changeInputWidht(PostTextInput);

    if (event.key === "Enter") {
      if (
        !PostTextInput.validity.patternMismatch &&
        !PostTextInput.validity.valueMissing
      ) {
        setText(event.target.value);
      } else {
        alert(
          "Please Enter a valid Name ! Only A-Z a-z characters allowed. Max length of 32 characters."
        );
      }

      event.preventDefault();
    }
  };
  const cancelchangePostTextOnEnter = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ): void => {
    const PostTextInput = document.getElementById(
      firstNameInputId
    ) as HTMLInputElement;
    PostTextInput.value = postText;
  };


  return (
    <div className="">
      <form>
        <input
          id={firstNameInputId}
          onMouseOver={addInputEditTag}
          onMouseLeave={removeInputEditTag}
          onKeyPress={changePostTextOnEnter}
          onBlur={cancelchangePostTextOnEnter}
          className="editableContain   border border-gray rounded-lg"
          name="postName"
          type="text"
          defaultValue={postText}
          required
        />
        <label className=" mr-4">{txtAddon}</label>
      </form>
    </div>
  );
};

export default EditableInputFirstName;
