import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../services/userService";
import userAvatar from "../images/userAvatar.png";
import { Link } from "react-router-dom";

type IProps = {
};

const Login: React.FC<IProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const initialValues: {
    username: string;
    password: string;
  } = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });
  const handleLogin = (formValue: { username: string; password: string }) => {
    localStorage.setItem("aqsLogged", "false");
    const { username, password } = formValue;
    setMessage("");
    setLoading(true);
    login(username, password).then(
      () => {
        window.location.reload();
      },
      (error) => {
        // eslint-disable-next-line no-unused-vars
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage("NON AUTHORIZÉ !");
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="my-10 flex flex-col justify-center items-center">
        <div className="flex justify-center h-0 relative">
          <img
            src={userAvatar}
            alt="profile-img"
            className="rounded-full h-32 w-32 bg-robins-egg-blue-100"
          />
        </div>
        <div className="bg-white/60 rounded-lg pt-14 mt-20 pb-5 w-fit pl-32 pr-32">
          <div className="flex justify-center mb-2 text-4xl font-extralight">
            <h1>Sign in</h1>
          </div>
          <div className="flex justify-center">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              <Form>
                <div className="flex flex-col mb-2 font-josefin">
                  <label htmlFor="username">Username</label>
                  <Field
                    name="username"
                    type="text"
                    className="form-control rounded-full mb-3"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="flex flex-col mb-2 font-josefin">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control rounded-full mb-3"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="flex flex-row form-group">
                  <div className="flex mr-2">
                    <button
                      type="submit"
                      className="border rounded px-2 bg-orange-ori-200 hover:bg-orange-ori-400 shadow-md"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>

                </div>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
          </div>

          <div className="mt-5">
            <span>Don&apos;t have an account?</span>
            <Link
              to={"/register"}
              className="text-blue-tag hover:italic hover:text-orange-ori-500 pl-2"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
