const URL = "https://checkout.aventurequatresaisons.ca:3000/reservation";

export const getFullResa = async <reservation>(
): Promise<reservation[]> => {
  const response = await fetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};


export const getResaDate = async <reservation>(
  date: string,
  ): Promise<reservation[]> => {
    const response = await fetch(`${URL}/onedate/${date}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
  
    if (!response.ok) {
      throw new Error(response.statusText);
    }
  
    try {
      return await response.json();
    } catch (err) {
      throw new Error(err as string);
    }
  };

export const patchResaInfo = async <reservation>(
  userId: string,
  newInfo: reservation
) => {
  // Ajouter le ID ou patche by name du UserContext
  const response = await fetch(`${URL}/${userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newInfo),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    alert(
      `Votre modification à été apporté ! nouvel Entrée : ${
        Object.values(newInfo)[0]
      }`
    );
  }
};
export const deleteResaDb = async (resaId: string) => {
  const response = await fetch(`${URL}/${resaId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
};