/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from "react";
import stag from "../images/stag.png";
import {
  deleteResaDb,
  getFullResa,
} from "../services/reservationService";
import { reservation } from "../types/reservation";
import EditableInputFirstName from "./editableLiveForm/EditableInputFirstName";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


type IProps = {
  reservation: reservation;
  setResas: (resas: reservation[]) => void;
  resas: reservation[];
};

const Master: React.FC<IProps> = ({ reservation, setResas, resas }) => {
  const [reseration, setReseration] = useState<reservation>(reservation);
  const [prixTxIn] = useState(reseration.creditCard4 * 1.15);

  const deleteResa = async ( ) => {

    await deleteResaDb(reseration._id);
    alert(`La réservation ID : ${reseration._id} à été supprimé !`);
    resas.forEach((resa, index) => {
      if (resa._id === reseration._id){
        resas.splice(index, 1);
      }
      
    });

    
    setResas([...resas]);
  };


  return (
      
      <div className=" ">
          
          <div className="flex flex-wrap content-end pl-8 bg-gray border-b-2 rounded border-r-2 border-y-electric-violet-300 borde border-x-electric-violet-700">
              {/* <div className="font-yeseva text-1xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"startDate"} newValue={reseration.startDate} fixedSized={true}/></div> */}
              <div className="font-yeseva text-1xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"heureDepart"} newValue={reseration.heureDepart.toString()} txtAddon={"H"}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"activite"} newValue={reseration.activite} fixedSized={true}/></div>
              <div className="font-yeseva text-xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nom"} newValue={reseration.nom} fixedSized={true}/></div>
              <div className="font-yeseva text-1xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"prenom"} newValue={reseration.prenom} fixedSized={true}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbPaxAd"} newValue={reseration.nbPaxAd.toString()} fixedSized={false} txtAddon={"Adu."}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbPaxEnf"} newValue={reseration.nbPaxEnf.toString()} fixedSized={false} txtAddon={"Enf."}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbCanot"} newValue={reseration.nbCanot.toString()} fixedSized={false} txtAddon={"Canots"}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbKayak"} newValue={reseration.nbKayak.toString()} fixedSized={false} txtAddon={"Kayaks"}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbSUP"} newValue={reseration.nbSUP.toString()} fixedSized={false} txtAddon={"SUPs"}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"duree"} newValue={reseration.duree.toString()} fixedSized={false}  txtAddon={"jour(s)"}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"creditCard4"} newValue={((reseration.creditCard4 * 1.15).toFixed(2)).toString()} fixedSized2={true}  txtAddon={"$"}/></div>
              <div className="font-yeseva text-xs  mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"creditCard3"} newValue={reseration.creditCard3.toString()} fixedSized={false} /></div>
             
          </div>
        <div className="flex justify-end">
          <div className="-mt-8 mr-8">
            <FontAwesomeIcon onClick={deleteResa} icon={faTrash} />
          </div>
        </div>
        

        
      </div>
  );
};

export default Master;
