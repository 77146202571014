/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from "react";
import stag from "../images/stag.png";
import {
  deleteResaDb,
  getFullResa,
} from "../services/reservationService";
import { reservation } from "../types/reservation";
import EditableInputFirstName from "./editableLiveForm/EditableInputFirstName";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


type IProps = {
  reservation: reservation;
  setResas: (resas: reservation[]) => void;
  resas: reservation[];
};

const Resa: React.FC<IProps> = ({ reservation, setResas, resas }) => {
  const [reseration, setReseration] = useState<reservation>(reservation);
  const [prixTxIn] = useState(reseration.creditCard4 * 1.15);

  const deleteResa = async ( ) => {

    await deleteResaDb(reseration._id);
    alert(`La réservation ID : ${reseration._id} à été supprimé !`);
    resas.forEach((resa, index) => {
      if (resa._id === reseration._id){
        resas.splice(index, 1);
      }
      
    });

    
    setResas([...resas]);
  };


  return (
    <div className="flex flex-row bg-white rounded-xl m-3 p-3">
      <div className="w-60 pt-10">
        <img className="h-80 w-80" src="http://www.aventurequatresaisons.com/images/canotact.jpg" alt="???" />
      </div>
      <div className="flex flex-col w-auto ">

      <div className="flex justify-end -mb-4">
          <FontAwesomeIcon onClick={deleteResa} icon={faTrash} />
      </div>
        
        <div className="flex flex-wrap rounded border border-electric-violet-800 m-4">
            <div className="font-yeseva text-2xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"startDate"} newValue={reseration.startDate}/></div>
            <div className="font-yeseva text-2xl mr-4 text-blue bg-robins-egg-blue-800/20 rounded-md "><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"activite"} newValue={reseration.activite}/></div>
            <div className="font-yeseva text-2xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nom"} newValue={reseration.nom}/></div>
            <div className="font-yeseva text-2xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"prenom"} newValue={reseration.prenom}/></div>
        </div>
        
        <div className="flex flex-wrap rounded border border-electric-violet-800 md-4">
        <div className="font-yeseva text-1xl mr-4"><p>mail : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"courriel"} newValue={reseration.courriel}/></p></div>
        <div className="font-yeseva text-1xl mr-4"><p>tel : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"tel"} newValue={reseration.tel}/></p></div>
        </div>
        <div className="flex justify-center m-4">
          <div className="flex flex-wrap rounded border border-electric-violet-800 mr-24">
              <div className="font-yeseva text-1xl mr-4"><p>Départ : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"heureDepart"} newValue={reseration.heureDepart.toString()}/> H</p></div>
              <div className="font-yeseva text-1xl mr-4"><p>Durée : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"duree"} newValue={reseration.duree.toString()}/> jour(s)</p></div>
          </div>
          <div className="flex flex-wrap w-auto rounded border border-electric-violet-800">
              <div className="font-yeseva text-1xl mr-4"><p>Adultes : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbPaxAd"} newValue={reseration.nbPaxAd.toString()}/></p></div>
              <div className="font-yeseva text-1xl mr-4"><p>Enfants : </p><EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbPaxEnf"} newValue={reseration.nbPaxEnf.toString()}/></div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center rounded border border-electric-violet-800 m-4">
          <div className="font-yeseva text-1xl mr-4"><p>canot(s) : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbCanot"} newValue={reseration.nbCanot.toString()}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>kayak(s) : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbKayak"} newValue={reseration.nbKayak.toString()}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>sup(s) : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"nbSUP"} newValue={reseration.nbSUP.toString()}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>yourte(s) : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"yourte"} newValue={reseration.yourte}/></p></div>
        </div>
        <div className="flex justify-center m-4">
          <div className="flex flex-wrap rounded border border-electric-violet-800">
              <div className="font-yeseva text-1xl mr-4"><p>Total($) : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"creditCard4"} newValue={reseration.creditCard4.toString()}/></p></div>
              <div className="font-yeseva text-1xl mr-4"><p>TxsIn($) : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"-"} newValue={prixTxIn.toFixed(2).toString()}/></p></div>
          </div>
        </div>
        <div className="flex flex-wrap rounded border border-electric-violet-800">
          <div className="font-yeseva text-1xl mr-4"><p>Date Résa : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"confDate"} newValue={reseration.confDate}/></p></div>
          <div className="font-yeseva text-1xl mr-4"><p>ID Résa : <EditableInputFirstName key={uuidv4()} resaId={reseration._id} fieldToChange={"_id"} newValue={reseration._id}/></p></div>
        </div>

        
      </div>
    </div>
  );
};

export default Resa;
