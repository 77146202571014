const URL = "https://checkout.aventurequatresaisons.ca:3000/inventaireDispo";
const URLJOURNEE = "https://checkout.aventurequatresaisons.ca:3000/journee";

export const getFullJournee = async <journee>(
): Promise<journee[]> => {
  const response = await fetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};



export const getJourneeDate = async <journee>(
  date: string,
  ): Promise<journee[]> => {
    const response = await fetch(`${URL}/onedate/${date}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
  
    if (!response.ok) {
      throw new Error(response.statusText);
    }
  
    try {
      return await response.json();
    } catch (err) {
      throw new Error(err as string);
    }
  };

export const patchJourneeInfo = async <journee>(
  userId: string,
  newInfo: journee
) => {
  // Ajouter le ID ou patche by name du UserContext
  const response = await fetch(`${URLJOURNEE}/${userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newInfo),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    alert(
      `Votre modification à été apporté ! nouvel Entrée : ${
        Object.values(newInfo)[0]
      }`
    );
  }
};
export const deleteJourneeDb = async (journeeId: string) => {
  const response = await fetch(`${URLJOURNEE}/${journeeId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
};