/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import {
  getFullJournee,
} from "../services/journeeService";
import Journee from "./Journee";
import { v4 as uuidv4 } from "uuid";
import { journee } from "../types/journee";

const JourneeList: React.FC = () => {
const [journees, setJournees] = useState<journee[]>([]);

useEffect(() => {
  getJournee();
}, []);

  const getJournee = async () => {
    try {
      const fetchedJournee: journee[] = await getFullJournee();
      setJournees(fetchedJournee);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div
      id="wall"
      className="flex flex-col ml-3 mr-1 pr-3 w-full scrollbar-thin scrollbar-thumb-white scrollbar-track-gray overflow-y-scroll"
    >
      <div>
        {journees.map((journee: journee) => (
          <Journee key={uuidv4()} journee={journee} setJournees={setJournees} journees={journees}/>
        ))}
      </div>
    </div>
  );

};

export default JourneeList;
